<template>
  <b-row>
    <b-col cols="3">
      <TreeView :setDong="setDong" :selected="dongSearch == '' ? '전체' : dongSearch"></TreeView>
    </b-col>
    <b-col cols="9">
      <b-card no-body class="mb-4">
        
      </b-card>
    </b-col>

  </b-row>
  
</template>

<script>
import TreeView from '../components/apps/TreeView';
export default {
  name: "NotReady",

  data: () => ({
    dongSearch: "",

  }),
  components: {TreeView},
  methods: {
    setDong(dong, isChild){
      if(isChild){
        this.dongSearch = dong;
      }
      else{
        this.dongSearch = "";
      }
    },
  },
  mounted() {
  },
};
</script>
